import React from "react"
import { Layout } from "../../components/Layout"
import { CTAButton } from "../../commonComponents"
import { WebinarMiddleSection } from "../../WebinarMiddleSection"
import "./styles.css"
import { Helmet } from "react-helmet"

const formId = "9ad8978f-c351-4609-9708-2754eb04c955"

export default function GenAIWebinar() {
  const styles = {
    fontContact: {
      color: "#fff",
      fontFamily: "DM Sans",
    },
    alignContent: {
      display: "flex",
      alignItems: "center",
    },
  }
  const getDescription = () => {
    return <p>Description</p>
  }
  return (
    <Layout
      title="Recap of Webinar on AI-Ready Support: Equipping Your Team for the GenAI Era"
      keywords="genai webinar"
      description="Join us for an insightful session on how to revolutionize your customer support by harnessing the power of Generative AI (GenAI)."
    >
      <Helmet>
        <link rel="canonical" href="https://irisagent.com/gen-ai-webinar/" />
      </Helmet>
      <section
        style={{
          paddingTop: "40px",
        }}
      >
        <section
          id=" webinar-bg"
          className=" webinar-bg"
          style={{
            // backgroundImage: 'url("/img/resources/post 13.jpeg")',
            minHeight: "620px",
          }}
        >
          <div className=" webinar-container" style={styles.fontContact}>
            <img
              className="webinarButton"
              src="/img/WebinarButton.svg"
              alt=""
            />

            <h1 className="webinar-title" style={styles.fontContact}>
              AI-Ready Support:<br/>
              Equipping Your Team<br/> for the GenAI Era
            </h1>

            <div className="subtitle-web" style={{ display: "flex" }}>
              <img
                className="calendar"
                src="/img/calendar21Oct.svg"
                alt=""
                width={360}
              />
              <p className="time">Sep 17, 2024 10:00 AM PT</p>
            </div>
            <CTAButton
                to="/gen-ai-webinar/watch-webinar/"
                label="WATCH WEBINAR RECORDING"
                color="#EF5DA8"
            />
          </div>
          <div className="webinarHeader">
            <img
              src="/img/WebinarHeader.svg"
              alt=""
              className="webinarHeaderImg"
            />
          </div>
        </section>
      </section>

      <WebinarMiddleSection
        mainImage="/img/gen-ai-webinar-speakers.png"
        // imageTextMiniature="/img/WebinarSpeakers.svg"
        backgroundSectionColor="#FFFFFF"
        signupLink="https://irisagent.com/gen-ai-webinar/watch-webinar/"
        descriptionTextTitle={<>Webinar Details</>}
        descriptionTextContent={<>
        Join us for an insightful session on how to revolutionize your customer support by harnessing the power of Generative AI (GenAI). In this webinar, industry experts will guide you through real-world examples and case studies, revealing how GenAI has driven over 40% deflection in customer inquiries. Discover actionable strategies to get your support team AI-ready, and gain practical tips to enhance your customer experience. Whether you’re just starting your GenAI journey or looking to optimize your existing processes, this webinar will provide the insights and tools you need to lead in the AI era.
        <br/><br/>
        <b>Key Takeaways:</b><br/>
        <ul>
<li>Understanding the evolution and future of customer support automation.</li>
<li>How to leverage GenAI to achieve higher deflection rates and improve customer satisfaction.</li>
<li>Practical advice on implementing and optimizing AI solutions in your support operations.</li>
<li>Real-world success stories and insights from industry experts.</li>
        </ul>

        </>}
      />
      {/* <!-- End Middle Section --> */}
    </Layout>
  )
}
